@use "../../styles/partials/mixins" as *;

.navbar {
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;

    &__content {
        margin: 0 auto;
        max-width: 80rem;
    }

    &__nav {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // padding: 1rem 0;
    }

    &__nav-list {
        display: flex;
        justify-content: space-around;
        list-style-type: none;
        align-items: center;
        padding: 0.25rem;

        @include tablet {
            justify-content: center;
        }
    }

    &__nav-link {
        font-size: 0.8rem;
        font-weight: 600;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        color: #718096;
        border-radius: 0.25rem;
        transition: color 0.2s ease;
        padding: 0.75rem 0.25rem;
        margin: 0 0.25rem;
        display: block;

        @include tablet {
            padding: 0.75rem 0.5rem;
        }

        &:active {
            text-decoration: none;
            color: #2e66e5;
            background-color: #e2e8f0;
        }

        &:hover {
            text-decoration: none;
        }

        @include desktop {
            &:hover {
                text-decoration: none;
                color: #2e66e5;
                background-color: #e2e8f0;
            }
        }
    }
}
