@use "./breakpoints" as *;
@use "./fonts";
@forward "./fonts";
@forward "./breakpoints";

@mixin rounded-borders($radius: 0.5rem) {
    border-radius: $radius;
}

$button-color: (
    "primary": #2e66e5,
    "secondary": #6c757d,
    "light": #ffffff,
    "dark": #13182c,
    "success": #28a745,
    "danger": #c94515,
    "warning": #ffc107,
);

@mixin flexbox($justify-content: null, $align-items: null, $direction: null, $wrap: null) {
    display: flex;
    justify-content: $justify-content;
    align-items: $align-items;
    flex-direction: $direction;
    flex-wrap: $wrap;
}

@mixin link() {
    color: #2e66e5;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

@mixin cta-button($variant: "primary") {
    @include fonts.labelsbuttons;
    text-decoration: none;
    background-color: map-get($map: $button-color, $key: $variant);
    @if ($variant== "light" or $variant== "warning") {
        color: #5c667e;
        border: 1px solid #bdc5d5;
    } @else {
        color: white;
        border: none;
    }
    @include rounded-borders();
    position: relative;
    padding: 0 1rem;
    width: 100%;
    height: 2.25rem;
    cursor: pointer;
    @include flexbox(center, center);

    @include tablet {
        width: auto;
        height: 2.375rem;
    }
}

@mixin text-field() {
    color: #13182c;
    border: 1px solid #bdc5d5;
    @include rounded-borders();
    outline: none;
    padding: 0 1rem;
    width: 100%;
    height: 2.25rem;

    @include tablet {
        width: auto;
        height: 2.375rem;
    }

    &:active,
    &:focus {
        border-color: #2e66e5;
    }

    &--error {
        border-color: #c94515;
    }

    &::placeholder {
        color: #5c667e;
    }
}

@mixin textarea() {
    font: inherit;
    color: #13182c;
    border: 1px solid #bdc5d5;
    @include rounded-borders();
    outline: none;
    padding: 0.5rem 1rem;
    width: 100%;
    min-height: 4.5rem;
    resize: none;

    @include tablet {
        width: auto;
    }

    &:active,
    &:focus {
        border-color: #2e66e5;
    }

    &--error {
        border-color: #c94515;
    }

    &::placeholder {
        color: #5c667e;
    }
}

@mixin select() {
    color: #13182c;
    border: 1px solid #bdc5d5;
    @include rounded-borders();
    outline: none;
    padding: 0 1rem;
    width: 100%;
    height: 2.25rem;

    @include tablet {
        width: auto;
        height: 2.375rem;
    }

    &:active,
    &:focus {
        border-color: #2e66e5;
    }

    &--error {
        border-color: #c94515;
    }
}

@mixin error-label {
    color: #c94515;
    margin-top: 0.25rem;
    display: block;
}

@mixin section-content {
    margin: auto;
    max-width: 1200px;
    width: 100%;
    padding: 2rem;
}
