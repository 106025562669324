@use "../../../../styles/partials/mixins" as *;

.project-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // gap: 0.5rem;

    & > * {
        margin-bottom: 3vh;
        @include tablet {
            flex: 1 1 30rem;
            max-width: 48%;
        }

        @include desktop {
            flex: 1 1 30rem;
            max-width: 32%;
        }
    }
}
