@use "./breakpoints" as *;

$font-family: "Titillium Web";

/// Creates a font value to be used with the CSS font attribute.
/// @param {Number} $weight - The font-weight
/// @param {Number} $size - The font-size
/// @param {Number} $height - The line-height
/// @param {String} $family [-family] - The font-family, defaults to the global $font-family
/// @return {Font} -
@function create-font($weight, $size: 1em, $height: 1, $family: $font-family) {
  @return $weight $size / #{$height} $family;
}

//# Font Values

//## Mobile
//Page Header
$font-pageheader-mobile: create-font(600, 1.75rem, 2.25rem);
//Section Header
$font-sectionheader-mobile: create-font(600, 1.5rem, 1);
//Subheader
$font-subheader-mobile: create-font(600, 0.75rem, 1.375rem);
//Body
$font-body-large-mobile: create-font(400, 0.9375rem, 1.125rem);
//Body
$font-body-mobile: create-font(400, 0.8125rem, 1.25rem);
//Body
$font-body-small-mobile: create-font(400, 0.6875rem, 1rem);
//Labels/Buttons
$font-labelsbuttons-mobile: create-font(600, 0.8125rem, 1.25rem);

//## Tablet/Desktop

//Page Header
$font-pageheader-tablet: create-font(600, 2rem, 2.5rem);
//Section Header
$font-sectionheader-tablet: create-font(600, 2.25rem, 1);
//Subheadder
$font-subheader-tablet: create-font(600, 0.75rem, 1.375rem);
//Body
$font-body-large-tablet: create-font(400, 1rem, 1.75rem);
//Body
$font-body-tablet: create-font(400, 0.875rem, 1.375rem);
//Body
$font-body-small-tablet: create-font(400, 0.75rem, 1.125rem);
//Labels/Buttons
$font-labelsbuttons-tablet: create-font(600, 0.875rem, 1.375rem);

//# Typography Mixins
@mixin pageheader {
  font: $font-pageheader-mobile;
  @include tablet {
    font: $font-pageheader-tablet;
  }
}

@mixin sectionheader {
  font: $font-sectionheader-mobile;
  @include tablet {
    font: $font-sectionheader-tablet;
  }
}

@mixin subheader {
  font: $font-subheader-mobile;
  @include tablet {
    font: $font-subheader-tablet;
  }
}

@mixin body-large {
  font: $font-body-large-mobile;
  @include tablet {
    font: $font-body-large-tablet;
  }
}

@mixin body {
  font: $font-body-mobile;
  @include tablet {
    font: $font-body-tablet;
  }
}

@mixin body-small {
  font: $font-body-small-mobile;
  @include tablet {
    font: $font-body-small-tablet;
  }
}

@mixin labelsbuttons {
  font: $font-labelsbuttons-mobile;
  @include tablet {
    font: $font-labelsbuttons-tablet;
  }
}
