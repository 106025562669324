@use "./mixins";

*,
*::before,
*::after {
  box-sizing: border-box;
}

a,
.link {
  @include mixins.link;
}

.cta-button {
  @include mixins.cta-button;

  &--light {
    @include mixins.cta-button("light");
  }

  &--secondary {
    @include mixins.cta-button("secondary");
  }

  &--dark {
    @include mixins.cta-button("dark");
  }

  &--success {
    @include mixins.cta-button("success");
  }

  &--danger {
    @include mixins.cta-button("danger");
  }

  &--warning {
    @include mixins.cta-button("warning");
  }
}

.text-field {
  @include mixins.text-field();
}

.textarea {
  @include mixins.textarea;
}

.select {
  @include mixins.select;
}

.error-label {
  @include mixins.error-label;
}
