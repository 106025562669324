//# Resets
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: unset;
  font-style: normal;
  text-decoration: none;
  font-family: unset; //Browsers override on some input fields
  font-size: 1em; //Firefox overrides on some input fields
}

html,
body {
  width: 100%;
  height: 100%;
}
