@use "../../styles/partials/mixins" as *;

.contact-section {
    &__content {
        @include section-content;
        padding: 3rem 0 6rem;
        width: 90%;
        display: flex;
        flex-direction: column;

        @include tablet() {
            max-width: 50%;
        }
    }

    &__heading {
        font-size: 2rem;
        font-weight: 600;
        margin-top: 4rem;
        margin-left: 0.5rem;

        @include desktop {
            font-size: 3rem;
        }
    }

    &__social-list {
        padding: 0.5rem 1rem 0;
        display: flex;
    }

    &__social-icon {
        margin-right: 0.75rem;
        transition: filter 0.2s ease;

        &:hover {
            filter: brightness(1.5);
        }
    }
}
