@use "../../styles/partials/mixins" as *;

.hero-section {
    min-height: 100vh;
    display: flex;
    text-align: center;

    @include desktop {
        text-align: left;
    }

    &__content {
        @include section-content();
        padding: 3.25rem 2rem;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @include desktop {
            min-height: auto;
        }
    }

    &__row {
        display: flex;
        // flex-wrap: wrap-reverse;
        flex-direction: column-reverse;
        align-items: center;

        @include desktop {
            // flex-wrap: nowrap;
            flex-direction: row;
        }
    }

    &__container {
        &--tagline {
            flex: 0 0 auto;

            @include desktop {
                width: 66%;
            }
        }
        &--avatar {
            flex: 0 0 33%;
            // width: 33%;
        }
    }

    &__greet {
        font-size: 2.5rem;
        font-weight: 300;
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    &__tagline {
        font-size: 1.75rem;
        font-weight: 300;
        margin-top: 5vh;
        margin-bottom: 5vh;
        padding-top: 5vh;
        padding-bottom: 5vh;

        @include tablet {
            font-size: 2rem;
        }

        @include desktop {
            margin-top: 3rem;
            margin-bottom: 3rem;
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }

    &__links {
        margin: auto;

        display: flex;
        justify-content: space-around;

        @include tablet {
            width: 50%;
        }
    }

    &__link {
        color: #718096;
        &:hover {
            text-decoration: none;
            color: #2e66e5;
        }
    }

    &__icon-button {
        border: none;
        cursor: pointer;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;

        &,
        * {
            transition: all 0.2s ease-in-out;
        }

        @include tablet {
            &:hover {
                svg {
                    filter: contrast(0) sepia(5) brightness(1) hue-rotate(130deg) saturate(5);
                    transform: scale(1.05, 1.05) translateY(-0.125rem);
                }
            }
        }
    }

    &__link-text {
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        margin: 0.5rem 0;
    }
}

.profile-photo {
    max-width: 300px;
    border-radius: 150px;
}
