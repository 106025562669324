@use "../../styles/partials/mixins" as *;

.about-section {
    background-color: #edf2f7;
    padding-top: 4rem;
    padding-bottom: 2rem;

    &__content {
        @include section-content();

        @include tablet() {
            max-width: 75%;
        }

        @include desktop() {
            max-width: 50%;
        }
    }

    &__heading {
        font-size: 2rem;
        font-weight: 600;
    }

    &__body {
        line-height: 1.75;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}
