@use "../../styles/partials/mixins" as *;

.projects-section {
    background-color: #edf2f7;
    min-height: 100vh;

    &__content {
        padding: 2rem 10vw;
        margin: auto;

        @include tablet {
            padding: 1rem 6.25rem;
        }

        @include desktop {
            padding: 2rem 0;
            max-width: 80%;
        }
    }

    &__heading {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.8125rem;
        margin-top: 2rem;
        padding-bottom: 0.75rem;
    }
}
