@font-face {
    font-family: "Titillium Web";
    src: url(../../assets/fonts/titilliumweb-regular.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Titillium Web";
    src: url(../../assets/fonts/titilliumweb-italic.ttf) format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Titillium Web";
    src: url(../../assets/fonts/titilliumweb-light.ttf) format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Titillium Web";
    src: url(../../assets/fonts/titilliumweb-lightitalic.ttf) format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Titillium Web";
    src: url(../../assets/fonts/titilliumweb-semibold.ttf) format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Titillium Web";
    src: url(../../assets/fonts/titilliumweb-semibolditalic.ttf) format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Titillium Web";
    src: url(../../assets/fonts/titilliumweb-bold.ttf) format("truetype");
    font-weight: 700;
}

// @font-face {
//     font-family: 'avenir-alternate';
//     src: url(../../assets/Font/Alternative\ files/AvenirNexLTPro.ttf) format("truetype");
//     font-weight: 400;
// }

// @font-face {
//     font-family: 'avenir-alternate';
//     src: url(../../assets/Font/Alternative\ files/AvenirNextLTProBold.ttf) format("truetype");
//     font-weight: 700;
// }
