@use "../../../../styles/partials/mixins" as *;

.contact-form {
    padding: 1.5rem 0;

    &__container {
        padding: 1.5rem 1.5rem 0;
    }

    &__form-control-label {
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        color: #4a5568;
        margin-bottom: 0.25rem;
        display: block;
    }

    &__form-control-input {
        background-color: #edf2f7;
        border-radius: 0.25rem;
        border: 2px solid #edf2f7;
        padding: 0.5rem 1rem;
        width: 100%;
        transition: transform 0.2s ease;

        &:focus {
            outline: 0;
            border-color: #4299e1;
        }

        &:hover {
            transform: scale(1.02);
        }

        &::placeholder {
            color: #a0aec0;
        }
    }

    &__button {
        font-weight: 700;
        font-size: 0.875rem;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        color: #fff;
        background-color: #4299e1;
        border-radius: 0.25rem;
        border: 2px solid #4299e1;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;
        transition: transform 0.2s ease;
        padding: 0.5rem 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 0.25rem;

        @include tablet {
            width: 50%;
        }

        @include desktop {
            width: auto;
        }

        &:hover {
            transform: scale(1.02);
        }
    }
}
