.project-card {
    background-color: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    overflow: hidden;

    &__top-row {
        margin: 2rem;
        display: flex;
        justify-content: center;
    }

    &__bottom-row {
        margin: 2rem;
    }

    &__thumbnail {
        width: 16rem;
    }

    &__title {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.875rem;
    }

    // &__tech-list {
    // }
    &__tech-item {
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #718096;
    }

    &__description {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__link-list {
        display: flex;
    }

    &__link-item {
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: "#3182ce";
        border-radius: 0.25rem;
        transition: background-color 0.2s ease, filter 1s ease-in-out; // 0.5s;

        &:hover {
            background-color: #e2e8f0;
            filter: saturate(1.2) hue-rotate(360deg);
        }
    }

    &__link {
        padding: 0.625rem;
        display: flex;
        align-items: center;
    }

    &__link-text {
        display: block;
        padding: 0 0.25rem; //0.5rem 0;
    }
}
