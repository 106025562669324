@use "../../styles/partials/mixins" as *;

.skills-section {
    background-color: #edf2f7;

    .skills-section__content {
        @include section-content;
    }

    .skills-section__heading {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.8125rem;
        margin-top: 2rem;
        padding-bottom: 0.75rem;
    }
}

.skills-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;

    &__item {
        border: 1px solid #e2e8f0;
        border-radius: 0.25rem;
        padding: 0.5rem;

        display: flex;
        flex-direction: column;
        align-items: center;

        width: 33.333%;

        @include desktop {
            width: 20%;
        }
    }

    &__icon {
        width: 64px;
        height: 64px;
    }

    &__text {
        font-weight: 600;
        padding: 1rem 0 0.5rem;
    }
}
